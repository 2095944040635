@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=swap');
@font-face{font-family:"fontfutura";src:url("https://cdn.rawgit.com/KompiAjaib/font/master/product-sans-regular.ttf") format("ttf");font-weight:normal;font-style:normal;}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Condensed:400,600');
@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Fuzzy+Bubbles:wght@700&display=swap');
:root {
    --mainColor:linear-gradient(to bottom, rgb(0, 23, 65), rgb(1, 84,152), rgb(204, 188, 175));
    --mainColor1:rgb(204, 188, 175);
    --mainColor2:rgb(1, 84,152);
    --mainColor3:rgb(0, 23, 65);
    --mainColor4:#184A73;
    --mainColor5:#fd0b02;
    --mainColor6:#15be05;
    --mainColor7:#BDD7E2;
	  --mainColor8:#000FFF;
    --light-night: #292929;
    --text-color-light: rgb(223, 223, 223);
    --comment-area: rgb(165, 165, 165);
    --backColor1:#9dc2ff;
    --backColor2:#0066be;
    --separateColor:rgb(0, 50, 97);
    --words:#555;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

body {
    font-family:'segoe print',"acme", Geneva, Verdana, sans-serif;
    /* font-size: 16px; */
    display: flex;
    flex-direction: column;
}
/*sidebar.................*/

strong {
  font-weight: 600;
}

#nav-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  z-index: 6;
}
#nav-container .bg {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100%);
  visibility: hidden;
  opacity: 0;
  transition: .5s;
  background: #000;
}
#nav-container:focus-within .bg {
  visibility: visible;
  opacity: .6;
}
#nav-container * {
  visibility: hidden;
}

.button {
  position: fixed;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  appearance: none;
  background: transparent;
  left: 16px;
  top: 0;
  height: 56px;
  width: 30px;
  pointer-events: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.icon-bar {
  display: block;
  width: 20px;
  height: 3px;
  background: #FFFFFF;
  transition: .50s;
  border-radius: 5px;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  transform: translate3d(0,8px,0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  transform: translate3d(0,-8px,0) rotate(-45deg);
}

#nav-content {
  margin-top: 56px;
  padding-top: 25px;
  width: 250px;
  position: absolute;
  border-radius: 10px;
  top: 5px;
  left: 5px;
  height: calc(100% - 200px);
  background-color:#FFFFFF;
  pointer-events: auto;
  transform: translateX(-105%);
  transition: transform 0.5s;
  will-change: transform;
}

#nav-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#nav-content li a {
  display: block;
  height: 30px;
  width: 100%;
  color: #292929;
  padding-left: 16px;
  box-sizing: border-box;
  transition: all .5s ease;
}

#nav-content li a:hover {
  color: #7e7f80;
}

#nav-content li:not(.small) + .small {
  margin-top: auto;
}

.small {
  display: flex;
  align-self: center;
}


#nav-container:focus-within #nav-content {
  transform: none;
}

@media screen and (max-width:675px){
  #nav-container * {
    visibility: visible;
    }  
}
/*sidebar end...............*/

/* top bar start........... */
.top-menu {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 56px;
    background-color: var(--bkgfoot);
    background-repeat: no-repeat;
    /* background-size: cover; */
    z-index: 2;
}

#trademark{
    position: absolute;
    left:10px;
    width: 125px;
    height: 40px;
    bottom: 8px;
}

#trademark img{
    position: relative;
    width: 32px;
    top: 9px;
}

#trademark span{
    visibility: visible;
    bottom: 2px;
}
.xmas{
  left: 85px;
}

/* Ecomm redirect button start*/

.custom-btn {
  position: absolute;
  bottom:3px;
  right:235px;
  width: 120px;
  height: 30px;
  color: #fff;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}
/* 11 */
.btn-11 {
  border: none;
  /* background: rgb(0, 182, 70); */
  background: linear-gradient(0deg, rgba(255,151,0,1) 0%, rgba(251,75,2,1) 100%);
    color: #fff;
    overflow: hidden;
}

#span1{
  display: inline;
}

#span2{
  display: none;
}
#span3{
  display: none;
}

.btn-11 a{
  color: #FFFFFF;
}
.btn-11:hover {
    text-decoration: none;
    color: #fff;
    opacity: .7;
}
.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #FFFFFF;
    animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

/* Ecomm redirect button end */

#marge {
    position: static;
    height: 56px;
    width: 100%;
}
nav {
    position: absolute;
    right: 40px;
    bottom: 8px;
    width: 200px;
    display: table;
    margin: 0 auto;
}
nav a {
    position: relative;
    width: 33.333%;
    display: table-cell;
    text-align: center;
    color: #949494;
    font-size: 12px;
    text-decoration: none;
    /* padding:0 10px; */
    transition: 0.2s ease color;
}

nav a:before,
nav a:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.2s ease transform;
}

nav a:before {
    top: 0;
    left: 10px;
    width: 6px;
    height: 6px;
}

nav a:after {
    top: 5px;
    left: 18px;
    width: 4px;
    height: 4px
}

nav a:nth-child(1):before {
    background-color: #35FF49;
    left: 15%;
}

nav a:nth-child(2):before {
    background-color: #FFFFFF;
    left: 15%;
}

nav a:nth-child(3):before {
    background-color: #EB2020;
    left: 15%;
}

.nav-active{
    color:#FFFFFF;
}

.nav-active::after{
    content:"";
    color:#FFFFFF;
    width: 50%;
    height: 2px;
    position: absolute;
    top: 85%;
    left: 50%;
    background-color: #fff;
    border-radius: 25px;
    transition: 0.2s ease left;
    transform: translateX(-50%);
}

nav a:hover {
    color: rgb(209, 209, 209);
}

nav a:hover:before {
    transform: scale(1);
}


#home {
    position: absolute;
    left: 0;
    bottom: 0;
    
}

#joindre{
    position: absolute;
    bottom:10px;
    right: 10px;
}

#home img {
    padding: 8px;
}

/* top-bar end...........*/

/*Solo head.............*/
#marge1 {
    height: 60px;
    width: 100%;
}

#ref{
    color: var(--primary-line-color);
    font-size: 16px;
    margin:10px 0 0 20px;
}

#ref a{
    color:var(--mainColor6)
}

.headboard_solo {
    position: fixed;
    height: 55px;
    width: 100%;
    background-size: cover;
}

.top-menu p{
    position: absolute;
    right: 0;
    left: 0;
    top: -35px;
}


.headboard_solo #back img {
    left: -8px;
    top: 10px;
    width: 30px;
    position: fixed;
    cursor: pointer;
    left: 16px;
    top: 12px;
    height: 24px;
    width: 24px;
}

.headboard_solo p {
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    margin-bottom: 11px;
}

/*Solo head end................*/

.branding{
    display: flex;
    flex-direction: column;
    min-height: 160px;
    background: var(--bkgfoot);
    margin: 5px;
    background-size: cover;
}
.intro{
    flex-direction: column;
    min-height: 175px;
    background-size: cover;
    border-radius: 0 0 10px 10px;
}

#tel{
  position: relative;
  width: 16px;
  top: 3px;
}

.titre-home{
    font-family:'Montserrat';
    color:#ffffff;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 10px;
}

.titre-home h2{
    display: inline-block;
    padding:5p 0 0 5px;
}

.titre-home i{
    position: relative;
    padding: 10px;
    cursor: pointer;
    top:-2px;
    margin: 10px 5px;
}

.apearance2{
  display: none;
}

.intro span{
    position: absolute;
    padding:10px;
    color:#FFFFFF;
    font-size: 16px;
}

#lettrine{
    font-size: 50px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

#dvr{
  display: inline;
}

.enBref{
    color: #FFFFFF;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0 0 10px 10px;
    bottom: 0;
    font-size: 14px;
}

.enBref a{
    color:white;
    font-weight: bold;
}

.containerHome{
    display:flex;
    flex-wrap: wrap;
    padding:10px;
    justify-content: space-around;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.article{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    width: 30%;
    min-height: 175px;
    margin: 10px 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    animation-duration: 0.25s;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 10px;
}

.artbkg{
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 220px;
    border-radius: 10px 10px 0 0;
}

.article:hover{
    transition: all 150ms ease;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.artitle{
    display: flex;
    flex-direction: row;
    line-height: 18px;
    margin: 8px;
}

.artserv{
    margin:20px 5px 20px 5px;
    color: var(--words);
    font-size: 15px;
    font-family: 'Short Stack';
}

#plus{
    position: relative;
    font-family: 'Short Stack';
    font-size: 10px;
    bottom: 8px;
    text-align: center;
}

.butItem:hover{
    background-color: var(--backColor1);
    transform: scale(1);
}

.slowFade {
    display: flex;
    background: #fff;
    width: 300px;
    height: 200px;
    overflow: hidden;
    position: relative;
    z-index: -1;
}

.slowFade .slide img {
    position: absolute;
    width: 300px;
    height: auto;
    background: #000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            opacity: 0;
            transform: scale(1.5) rotate(15deg);
    -webkit-animation: slowFade 32s infinite;
            animation: slowFade 32s infinite;
}
.slowFade .slide:nth-child(5) img {
    -webkit-animation-delay: 8s;
            animation-delay: 8s;
}
.slowFade .slide:nth-child(4) img {
    -webkit-animation-delay: 16s;
            animation-delay: 16s;
}
.slowFade .slide:nth-child(3) img {
    -webkit-animation-delay: 24s;
            animation-delay: 24s;
}

.slowFade .slide:nth-child(2) img {
    -webkit-animation-delay: 32s;
            animation-delay: 32s;
}

.slowFade .slide:nth-child(1) img {
    -webkit-animation-delay: 40s;
            animation-delay: 40s;
}

@keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

/*Services body .................*/
.section-intro {
    position: relative;
}
.section-intro .img, 
.section-intro .trame {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    background-repeat: no-repeat;
}
.section-intro.autosize {
    height: auto;
}.section-intro.autosize .titre,
.section-intro.autosize .cta {
    position: relative;
    width: 100%;
    height: auto;
    top: auto;
}
.section-intro .container {
    position: relative;
    height: 100%;
}
section {
    display: block;
    box-sizing: border-box;
}
.section-blog {
    -webkit-background-size: 30% auto;
    background-size: 30% auto;
}
.container {
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    color: var(--words);
    /* text-align: justify; */
}
.container::before{
    content: " ";
    display: table;
}
.container::after{
    clear: both;
}
.section-blog-article>.inner {
  position: relative;
  padding-top: 10px;
}
.section-blog-article .top,
.section-blog-article .share-and-vote .stickable>.inner {
  font-family: 'Montserrat',sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #757575;
  margin-bottom: 1px;
  margin-top: 1px;
  padding: 20px 10px;
}
.section-blog-article .top,
.section-blog-article .body,
.section-blog-article .bottom,
.section-blog-article .share-and-vote {
  background-color: #fff;
  overflow: auto;
}
.section-intro .titre {
  display: table;
  height: 140px;
  top: 50px;
}
.section-intro .cta {
  top: 200px;
  text-align: center;
}
.section-intro .titre>.inner .categories {
  font-family: 'Montserrat',sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 5px;
}
.inner h1 {
  display: block;
  /* font-size: 2em; */
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.section-blog-article .body {
  padding: 10px;
  font-size: 18px;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}
.blocs{
  margin: 10px 0 10px 0;
}
.body img{
  width: 200px;
  float: left;
  padding:0 10px 10px 0;
  border-radius:10px;
}

#conseils{
  font-family:'Short Stack','Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  color: var(--mainColor5);
  margin: 0.83em 0 56px 0;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

#solar{
  color: var(--mainColor6);
}

.body h2 {
  font-family:'Short Stack','Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  color: var(--mainColor6);
  margin: 0.83em 0;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.body h3{
  font-size: 20px;
  font-family: 'Short Stack','Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  color: var(--mainColor6);
  margin: 0.83em 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.body h4{
  font-size: 16px;
  font-family: 'Short Stack','Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  color: var(--mainColor6);
  margin: 0.83em 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 0.5em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.body h5{
  font-size: 17px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bold;
  font-style: normal;
  text-transform: none;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  margin: 0.83em 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 0.5em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.body p{
  font-family: 'Fira Sans', sans-serif;
  font-size: 1rem;
  margin-top: 0.5em;
  margin-bottom: 0em;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
.body a{
  outline: 0;
  color: #202521;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}
.body #equip{
  /* font-weight: bold; */
  font-size: 25px;
}
.body ul{
    list-style-type: disc;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.body ol{
  margin-left: 19px;
}

.section-intro .titre>.inner h1,
.section-intro .titre>.inner .h1 {
    margin: 0;
    font-family: 'Montserrat',sans-serif;
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    line-height: 1.1;
}
.section-intro.autosize .titre h1,
.section-intro.autosize .titre .h1 {
    margin: 0.5em 0 0.8em 0;
    min-height: 3.3em;
    font-size: 2em;
    margin: 0.67em 0;
}
.body li{
    display: list-item;
    text-align: -webkit-match-parent;
}

/*services body .............*/

@-webkit-keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

/*show slide end ....................*/

/*Q&R start................*/
.faq {
    padding: 25px;
}

.faq h3 {
    color: #3987AE;
    font-size: 15px;
    padding-bottom: 10px;
}

.premium {
    color: #FE8E0B;
    font-weight: bold;
}

.faq .bold {
    font-weight: bold;
}

.faq .notif {
    color: #2855C9;
    font-weight: bold;
}
/*Q&R end................*/


/*Infos de l'appli start.......*/
.info-content {
    background-color: var(--bkgfoot);
    text-align: center;
    padding: 100px 0;
    border-radius: 10px;
    color: var(--text-color-light);
}

.info-content img{
    width: 50px;
}
/*Infos de l'appli end.......*/

/*Comment starting.......*/
.comment-in {
    display: flex;
    flex-direction: column;
    background: var(--light-night);
    max-width: 700px;
    align-self: center;
    padding: 10px;
    margin: 20px;
    color: var(--text-color-light);
    border-radius: 15px;
}

#comment {
    background:rgb(245, 245, 245);
    width: 100%;
    min-height: 170px;
    border: none;
    border-radius: 15px;
    font-family: "Montserrat",sans-serif;
    color: #555;
    margin-bottom: 10px;
    padding: 2px 10px;
    line-height: 1.8;
}

#author,
#email{
    display: block;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    color: #555;
    font: 15px"Montserrat",sans-serif;
    font-weight: 500;
    letter-spacing: .3px;
    padding: 12px 30px;
    height: 30px;
    width: 100%;
    margin-bottom: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

/*submit style*/

.submit {
  display: block;
  margin: 1em auto 0 auto;
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  padding: 0.5em 1em;
  cursor: pointer;
  color: var(--color);
  transition: 0.25s;
  border-radius: 5px;
  --color: var(--mainColor6);
}
.submit:hover{
    border-color: var(--hover);
    color: #60fc52;
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
}
/*submit sytle end*/

#lastTextA{
    display: none !important;
}

.antispam-group, .antispam-group-e{
    display: none;
}

#phide{
    display: none;
}

.antispam-group, .antispam-group-q{
    clear: both;
    display: none;
}
/*Comment ending............*/

/*cloud image start.........*/
.ImgCloud{
    width: 400px;
}
/*cloud image end.........*/

/*Coming soon start...........*/
.sooner{
    padding-top: 75px;
    max-height: 500px;
    align-content: space-between;
    background: var(--bkgfoot);
    font-family:'Noto Sans', sans-serif;
}

.sooner img{
    width: 45%;
}

.sooner h3 {
	color: #FFFFFF;
	font-size: 1.5em;
	text-align: center;
    padding: 10px;
}

.sooner h4{
    color:#FFFFFF;
    text-align: right;
    padding:10px;
    background-color: rgba(0, 23, 65, 0.404);

}

/*Coming soon end............*/

/*About us start..............*/

.propos img {
float: left;
height: 600px;
shape-outside: ellipse(224px 320px at 35.1% 50%);
}

.propos,
.title1 {
background-color: #222222;
color: #dfe3e8;
margin: 0 auto;
padding: 10px;
max-width: 60rem;
line-height: 1.7;
}

.title1 {
font-size: 2.5rem;
font-weight: 700;
line-height: 1.1;
margin: 0 0 0.5rem 0;
padding: 3.5rem 0 0;
}

.title1 span {
color: #FFFFFF;
font-size: 2rem;
font-family:"segoe print",'acme', sans-serif;
font-weight: 400;
line-height: 1.2;
margin: 0;
}

@supports (shape-outside: circle()) {
.title1 {
padding: 2rem 0 0;
}
}
.concl {
color: #cc9966;
font-style: italic;
font-size: 1.15rem;
text-shadow: 1px 1px 1px #333;
}

.propos p {
font-size: 0.9rem;
margin: 0;
padding-top: 1.5rem;
}

.propos strong{
  font-family: 'fuzzy';
}

.first {
padding-top: 1.22rem;
}

@media screen and (max-width:660px) {
  .propos img {
    height: 400px;
    shape-outside: ellipse(224px 320px at 35.1% 50%);
    }
}
/* FAQ */
.group:focus .group-focus\:flex {
	display: flex;
}
/* FAQ */
/*About us ends...............*/
:root{
  --primary-light-blue: #8DB9ED;
  --primary-line-color: #AAA;
  --bkgfoot2:#222;
  --bkgfoot: #1f1e28;
  --linebrake: rgba(108, 127, 153, 0.2);
  --bkgfoot3: rgb(55, 55, 55);
  --foot: #757575;
}

main, footer {
    font: 11px "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
footer {
  background: var(--bkgfoot3);
  width: 100%;
  margin-top: 30px;
}
footer ul {
  list-style: none;
  padding:0 10px;
}
footer a {
  text-decoration: none;
  color: var(--foot);
}
.generic-anchor {
	color: var(--foot);
}
.nav-serv-active{
    color: #66d85b;
}
footer a:hover{
  color:var(--primary-line-color);
}
.flex-rw {
  display: flex;
  flex-flow: row wrap;
}

.block1{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 10px 0;
    background-color: var(--bkgfoot2);
}

.block1 a{
    font-size: 16px;
}

.block2{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: var(--bkgfoot);
    border-top: 0.5px solid var(--linebrake);
}

#badge{
    height: 35px;
    margin: 10px 5px 0 10px;
}

.footer-list-top > li {
  padding-bottom: 2px;
}
.footer-list-header {
  padding: 10px 0 5px 0;
  color: #fff;
  font: 2.3vw "Oswald", sans-serif;
}
.footer-list-anchor {
  font: 1.2em "Open Sans", sans-serif;
}
.footer-social-section {
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: var(--bkgfoot);
}
.footer-social-section::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  border-top: 1px solid var(--primary-line-color);
  width: calc(100% - 20px);
}
.footer-social-overlap {
  position: relative;
  z-index: 2;
  background: var(--bkgfoot);
}
.footer-social-connect {
  display: flex;
  align-items: center;
  font: 3em "Oswald", sans-serif;
  color: #fff;
}
.footer-social-small {
  font-size: 0.6em;
  padding: 0px 20px;
}
.footer-social-overlap > a {
  font-size: 3em;
}
.footer-social-overlap > a:not(:first-child) {
  margin-left: 0.38em
}

/*Style social start*/

a.socials-item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 5px;
    line-height: 16px;
    padding: 0 10px;
}

.socials-item i {
    display: inline-block;
    font-weight: normal;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-size: 16px;
    text-align: center;
    vertical-align: top;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
}

.facebook {
    color: #4e64b5;
}
.twitter {
    color: #00aced;
}
.linkedin{
    color:#0A66C2;
}
.youtube {
  color: #c82929;
}
.instagram {
    color: #9a8f62;
}

.footer-bottom-section {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--linebrake);
  background-color: var(--bkgfoot);
}
.footer-bottom-section > div:first-child {
  margin-right: auto
}
.footer-bottom-wrapper {
  color: #fff;
}
.hilighter{
    color: var(--primary-line-color);
}
.footer-address {
  display: inline;
  font-style: normal
}
@media only screen and (max-width: 768px) {
  .footer-list-header {
    font-size: 2em
  }
  .footer-list-anchor {
    font-size: 1.1em
  }
  .footer-social-connect {
    font-size: 2.5em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
}

@media screen and (max-width:610px){
    footer ul{
        width: 45%;
    }
}
@media only screen and (max-width: 568px) {
  .footer-social-section::after {
    top: 25%
  }
  .footer-social-connect {
    margin-bottom: 10px;
    padding: 0 10px
  }
  .footer-social-overlap {
    display: flex;
    justify-content: center;
  }
  .footer-social-icons-wrapper {
    width: 100%;
    padding: 0 0 5px 0
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 20px;
  }
  .footer-bottom-section {
    padding: 0 5px 10px 5px
  }
  .footer-bottom-wrapper {
    text-align: center;
    width: 100%;
    margin-top: 10px
  }
}
@media only screen and (max-width: 480px) {
    .footer-social-connect {
        font-size: 2em;
      }
  .footer-social-overlap > a {
    margin: auto;
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 0;
  }
  .footer-bottom-rights {
    display: block;
  }
  .block1{
    padding: 0;
  }
}
@media screen and (max-width: 420px){
    .block1{
        justify-content: center;
    }
}
@media only screen and (max-width: 320px) {
  .footer-list-header {
    font-size: 2em
  }
  .footer-social-connect {
    font-size: 1.5em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
}

/*Footer ends................*/

/*Page not found..............*/
.left-section .inner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inner-content img{
  width: 75px;
  display: block;
  margin: 0 auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#0C0E10, #446182);
}
.background .ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25vh;
  background: #0C0E10;
}

.notFound {
  font-family: "Fira Sans", sans-serif;
  color:#f5f6fa;
  position: relative;
  margin: 0 auto;
  width: 85%;
  height: 100vh;
  padding-bottom: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.left-section, .right-section {
  position: relative;
}

.left-section {
  width: 40%;
}

.heading {
  text-align: center;
  font-size: 5em;
  line-height: 1.3em;
  margin: 0 0 0.5rem 0;
  padding: 0;
  text-shadow: 0 0 1rem #fefefe;
}
.subheading {
  text-align: center;
  font-size: 1em;
  line-height: 1.15em;
  /* margin: 0 auto; */
  padding: 0 1rem;
}

.right-section {
  width: 50%;
}

.svgimg {
  position: absolute;
  bottom: 0;
  padding-top: 10vh;
  padding-left: 1vh;
  max-width: 100%;
  max-height: 100%;
}
.svgimg .bench-legs {
  fill: #0C0E10;
}
.svgimg .top-bench, .svgimg .bottom-bench {
  stroke: #0C0E10;
  stroke-width: 1px;
  fill: #5B3E2B;
}
.svgimg .bottom-bench path:nth-child(1) {
  fill: #432d20;
}
.svgimg .lamp-details {
  fill: #202425;
}
.svgimg .lamp-accent {
  fill: #2c3133;
}
.svgimg .lamp-bottom {
  fill: linear-gradient(#202425, #0C0E10);
}
.svgimg .lamp-light {
  fill: #EFEFEF;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 1rem #fefefe;
  }
  50% {
    text-shadow: 0 0 1.85rem #ededed;
  }
  100% {
    text-shadow: 0 0 1rem #fefefe;
  }
}
@media (max-width: 770px) {
  .background .ground {
    height: 0vh;
  }
  .notFound {
    flex-direction: column;
    padding-bottom: 0vh;
  }
  .left-section {
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
  }
  .left-section .inner-content {
    position: relative;
    padding: 1rem 0;
  }
  .heading {
    font-size: 4em;
    line-height: 1.15;
    margin: 0;
  }
  .subheading {
    font-size: 1em;
    line-height: 1.15;
    max-width: 100%;
  }
  .right-section {
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
  }
  .svgimg {
    padding: 0;
  }
}

/* FAQ start....................... */

.faqzone{
  background: #222;
  width: 100%;
  font-family: sans-serif;
  color: white;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 0 2rem 2rem 2rem;
}

details {
  margin: 0 auto ;
  background: #ffffff;
  margin-bottom: .5rem;
  box-shadow: 0 .1rem 1rem -.5rem rgba(0,0,0,.4);
  border-radius: 5px;
  overflow: hidden;
  max-width: 1200px;
}

summary {
  padding: 1rem;
  display: block;
  background: rgb(218, 218, 218);
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

summary:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
details[open] > summary:before {
  transform: rotate(90deg);
}

details summary::-webkit-details-marker {
  display:none;
}

details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

details ol{
  margin-left: 2.5rem;
}

.details-expanded {
    padding: 0 2rem 1rem 2rem;
}

pre {
    white-space: pre-wrap;
    word-break: break-all;
}

/* FAQ end......................... */

/*Page not found..............*/

/* Cookies2 start ...................*/

.cky-consent-bar.cky-box {
  padding: 30px;
  max-width: 476px;
  display: block;
  box-shadow: 0 -1px 10px 0 rgb(172 171 171 / 30%);
}
.cky-consent-bar {
  font-family: inherit;
  position: fixed;
  z-index: 9997;
}
.cky-consent-bar, .cky-consent-bar *,
.cky-consent-bar-trigger, .cky-consent-bar-trigger *,
.cky-modal, .cky-modal * {
  box-sizing: border-box;
}
.cky-consent-bar {
  font-family: inherit;
  position: fixed;
  z-index: 9997;
}
.cky-consent-bar p {
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
}
.cky-btn-readMore {
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  margin-left: 3px;
}
.cky-box .cky-button-wrapper {
  margin-top: 20px;
}
.cky-button-wrapper .cky-btn {
  margin-right: 15px;
}
.cky-btn {
  font-size: 12px;
  padding: 0.5rem 1rem;
  background: 0 0;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1;
  transition: all .15s ease-in-out;
  margin: 0;
  min-height: auto;
  font-weight: 400;
  border-radius: 0;
}
.cky-button-wrapper .cky-btn:last-child {
  margin-right: 0;
}

/* Cookies2 end...................... */

/* ContactBanner...................... */

blockquote{
  line-height: 1.5rem;
  position: relative;
  font-weight: 500;
  border: 2px solid rgba(94,94,94,.1);
  border-radius: 20px;
  padding: 15px;
  margin-top: 35px;
  background-color: #e7e7e7;
  text-align: center;
}

blockquote a{
  font-family: "acme", Geneva, Verdana, sans-serif;
}

blockquote #tel{
  float: none;
  padding:0;
  border-radius:0;
}

/* ContactBanner...................... */
/* Partenariat slider start*/

.slide-track h1 {
  text-align: center;
}
.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slide-track {
  display: flex;
  width: calc(250px * 10);
  animation: scroll 40s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 3));
  }
}
.slide {
  height: 200px;
  width: 250px;
  padding: 15px;
  display: flex;
  align-items: center;
  perspective: 100px;
}
.slide-track img {
  width: 100%;
  transition: transform 1s;
}
.slide-track img:hover {
  transform: translateZ(20px);
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: " ";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slide-track .details {
  position: absolute;
  top: 10px;
  left: 25px;
  bottom: 10px;
  right: 10px;
  width: 200px;
  background: rgba(0, 0, 0, 0);
  transform: scaleY(0);
  transition: transform 0.5s;
}
.slide:hover .details {
  transform: scaleY(1);
}
.details .content {
  position: absolute;
  top: 0%;
  transform: translateY(8%);
  text-align: center;
  color: white;
}

/* partenariat slider end*/

/* media queries */

@media (min-width: 1100px){
    .section-intro {
        width: 100%;
    }
    .container {
        width: 1000px;
    }
    .section-blog-article .top, .section-blog-article .share-and-vote .stickable>.inner {
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-blog-article .body, .section-blog-article .bottom {
        padding: 20px;
    }
    .section-intro.autosize .titre {
      padding-right: 35%;
    }
    .section-intro .cta {
        top: 180px;
        vertical-align: middle;
    }
}
@media screen and (max-width: 1100px) {
  .container{
      padding-left: 5%;
      padding-right: 5%;
  }
}
@media screen and (max-width: 1095px) {
    .artbkg{
        min-height: 160px;
    }
}

@media screen and (max-width: 1000px) {
  table {
        width: auto;
      }
}

@media (min-width: 992px)
    {.section-intro {
        width: 100%;
    }
}
@media screen and (max-width:950px){
    .article{
        width: 45%;
        margin: 5px;
      }
    }
    
    @media screen and (max-width:800px) {
    .welcome{
        border-radius: 10px 10px 10px 10px;
    }
    .imgOnboard {
      display: none;
    }
    caption {
      font-size: 20px;
    }
    #span1{
      display: none;
    }

    #span2{
      display: inline;
    }
    #span3{
      display: none;
    }
  }
  
  @media screen and (max-width:675px) {
    .custom-btn {
      position: absolute;
      /* top:3px; */
      right:40px;
      width: 120px;
      height: 30px;
    }
    .xmas{
      visibility: visible;
      left: 0;
    }
    nav{
      visibility: hidden;
    }

    label #btn,
    label #cancel {
        visibility: visible;
        z-index: 10;
    }
    #trademark{
      left: 60px;
    }
    #trademark span{
      visibility: hidden;
    }
    .apearance1{
      display: none;
      
    }
    .apearance2{
      display: inline-block;
    }
  }
  
@media screen and (max-width:585px){
    .welcome{
        visibility: hidden;
    }
    .welcome{
        display:none;
    }
}

@media screen and (max-width:500px){
    .resize{
        width:100%;
    }
    .article{
        width: 100%;
    }

    .artbkg{
        max-height: 200px;
    }
}

@media screen and (max-width:400px) {
    #trademark{
        position: absolute;
        left:45%;
        width: 125px;
        height: 40px;
        bottom: 8px;
    }
    .titre-home{
        font-size: 13px;
        margin-top: 20px;
    }

    nav {
        width: 100%;
    }
    .custom-btn {
      position: absolute;
      top:14px;
      right:50px;
      width: 75px;
      height: 30px;
    }
    #span2{
      display: none;
    }

    #span3{
      display: inline;
      position: relative;
      top:2px;
    }

    #gisto-header img {
        visibility: hidden;
    }
    .headboard-solo p {
        color: #FFFFFF;
        font-size: 17px;
        margin-bottom: 14px;
        margin-left: 45px;
    }
    .bottom {
        display: block;
    }
    .enBref{
        font-size: 13px;
    }
    .artbkg{
        min-height: 170px;
    }
}

@media screen and (max-width:317px){
    .slowFade {
        width: 100cv;
        height: 200px;
    }
    #palier1{
        display: flex;
    }
}
